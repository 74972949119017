/**
 * ruleTypes: 规则类型
 * genRules： rule 生成函数
 * ctx： Vue实例
 */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'level',
    itemProps: {
      label: '层数：',
      rules: [
      genRules('require')] } },



  {
    kname: 'code',
    itemProps: {
      label: '货位编码：',
      rules: [
      genRules('require')] }


    // component: {
    //   name: 'selector',
    //   props: {
    //     type: 'xxx'
    //   }
    // }
  },
  {
    kname: 'name',
    itemProps: {
      label: '预估容量：',
      rules: [
      genRules('require')] }


    // component: {
    //   name: 'selector',
    //   props: {
    //     type: 'xxx'
    //   }
    // }
  }];});



// form 默认值
export var formData = function formData() {return {};};